body, button {
    margin: 0;
    font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Fira Sans', 'Droid Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 18px;
}

p {
    margin: 0;
    padding: 0;
    font-size: 18px;
    font-family: 'Poppins';
}

.responsive-style {
    max-width: 1024px; 
    font-family: 'Poppins';
    font-size: 18px;
    margin-left: auto;
    margin-right: auto;
}

input {
    font-size: 18px;
    font-family: 'Poppins';
  }

select {
    font-size: 18px;
    font-family: 'Poppins';
}

/* Ipad 9th generation viewport sizes as this is what is current used by the team*/
@media only screen and (max-width: 768px) { 
    .responsive-style {
      max-width:768px;
    }

  }